import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import Modal from "react-modal";
import AddEditMember from "./AddEditMember";
import { callAdminApi } from "../../../utils/ApiInterface";
import LoadingSpinner from "../../../utils/Spinner";
import { customStyles } from "../../CustomStyle";
export default function MemberItem({ contacts }) {
  const { setDataDeleted, isDataUpdated } = useContext(AppContext);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [userDetail, setuserDetail] = useState({});
  const [isLoading, setLoading] = useState(false);

  const editDetails = (id) => {
    const findItem = contacts.find((item) => item.member_id === id);
    if (findItem) {
      setuserDetail(findItem);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    setModalOpen(false);
  }, [isDataUpdated]);

  const deleteuser = (event, id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptFunc(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

    const acceptFunc = (id) => {
      setLoading(true);
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/members/delete",
        "DELETE",
        {
          type: "json",
          auth: true,
          body: JSON.stringify({
            id: id,
          }),
          // body:JSON.stringify({leads:selctedIds})
        }
      )
        .then(async (data) => {
          setLoading(false);
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setDataDeleted(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <div
        id="customers"
        className="infowindow overflow-auto"
        style={{ height: "500px" }}
      >
        <table>
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Member Code</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Pharmacy Name</th>
              <th>Pharmacy Addres</th>
              <th>Founder Equity</th>
              <th>Member Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((user, index) => (
              <tr key={"_" + index + "_" + user.member_id}>
                <td>{index + 1}</td>
                <td>{user.member_code}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.contact}</td>
                <td>{user.pharmacy_name}</td>
                <td>{user.pharmacy_address}</td>
                <td>{user.founder_equity}</td>
                <td>{user.status ? "Active" : " Inactive"}</td>

                <td>
                  <button
                    onClick={(e) => deleteuser(e, user.member_id)}
                    className="btn btn-xs btn-danger"
                  >
                    Delete
                  </button>{" "}
                  <button
                    onClick={(e) => editDetails(user.member_id)}
                    className="btn btn-info  mx-1 btn-xs"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          <button onClick={(e) => setModalOpen(false)}>Close</button>

          <AddEditMember
            action="update"
            userDetail={userDetail}
          ></AddEditMember>
        </Modal>
      </div>
    </>
  );
}
