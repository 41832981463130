import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";
import { callAdminApi } from "../../../utils/ApiInterface";

import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";

export default function AddEditChannel({ action, userDetail }) {
  const initialState = {
    name: (userDetail && userDetail.name) || "",
    id: (userDetail && userDetail.channel_id) || "0",
  };

  const { setDataUpdated } = useContext(AppContext);
  const [inputData, setInputData] = useState(initialState);

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();

    callAdminApi(
      process.env.REACT_APP_BASEURL +
        `/admin/channels/${
          action === "update" ? "update" : "create"
        }`,
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify(inputData),
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDataUpdated(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <div
      id="customers"
      className="infowindow"
    >
      <form
        className="needs-validation"
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <MDBCardBody className="text-black d-flex flex-column justify-content-center">
          <h3 className="mb-5  mt-3 text-uppercase fw-bold">
            Enter Details to {action === "update" ? "Update" : "Add"} Channel
          </h3>

          <MDBRow>
            <MDBCol md="11">
              <MDBInput
                wrapperClass="mb-4"
                label="Name"
                size="lg"
                name="name"
                maxLength={100}
                required={true}
                value={inputData.name}
                onChange={onChange}
                type="text"
              />
            </MDBCol>

            
          </MDBRow>

         

          <div className="text-center pt-3">
            <MDBBtn className="ms-1 mb-2 row" color="warning" size="m">
              Submit
            </MDBBtn>
          </div>
        </MDBCardBody>
      </form>
    </div>
  );
}
