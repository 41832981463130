import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MDBCardBody, MDBRow } from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

const ResetPassword = () => {
  const [credentials, setCredentials] = useState({
    cpassword: "",
    password: "",
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userId = searchParams.get("reset_request_key");

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }

    e.preventDefault();

    if (credentials.password !== credentials.cpassword) {
      return toast.error("Password and Confirm password doesn't match", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    const response = await fetch(
      process.env.REACT_APP_BASEURL + "/admin/resetPassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adminId: userId,
          password: credentials.password,
        }),
      }
    );
    const data = await response.json();

    if (data.code === 200) {
      // Save the auth token and redirect
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate("/admin/login");
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className="bodyNav mt-2 row justify-content-center">
      <div className="col-md-4 border text-center mt-5 row mx-2 my-3">
        <form
          className="needs-validation"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <MDBCardBody className="text-black d-flex flex-column mt-4  justify-content-center">
            <h3 className="mb-5 text-uppercase fw-bold">
              Fill Details to Reset password
            </h3>

            <MDBRow>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  New Password
                </label>
                <input
                  required
                  type="password"
                  maxLength="16"
                  minLength={6}
                  className="form-control"
                  value={credentials.password}
                  onChange={onChange}
                  name="password"
                  id="password"
                  autoComplete="new-password"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Confirm Password
                </label>
                <input
                  required
                  type="password"
                  maxLength="16"
                  minLength={6}
                  className="form-control"
                  value={credentials.cpassword}
                  onChange={onChange}
                  name="cpassword"
                  id="password"
                />
              </div>
              <div className="justify-content-center">
                {" "}
                <button type="submit" className="btn w-50 mb-2 btn-primary">
                  Submit
                </button>
              </div>
            </MDBRow>
          </MDBCardBody>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
