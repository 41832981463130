import React, { useEffect, useState, useCallback } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { Row, Col } from "react-bootstrap";
import Topsection from "../../headers/Topsection";
import { callMemberApi } from "../../utils/ApiInterface";
import LoadingSpinner from "../../utils/Spinner";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import AccountSummary from "./AccountSummary";
const { afterToday } = DateRangePicker;

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [filterName, setFilterName] = useState("MONTH");
  const [data, setData] = useState({});
  const [options, setoptions] = useState({});
  const [optionsEquity, setOptionEquity] = useState({});

  const [rangeValue, setValue] = useState([]);

  const [payload, setPayload] = useState({
    filterName: filterName,
    range: "",
  });
  const generateDataPoints = (array) => {
    var dps = [];
    for (var i = 0; i < array.length; i++) {
      dps.push({
        y: Number(array[i].totalSum),
        x: new Date(array[i].createdAt),
      });
    }
    return dps;
  };

  const generateDataPointsEquity = (array) => {
    var dps = [];
    for (var i = 0; i < array.length; i++) {
      dps.push({
        y: Number(array[i].founder_equity),
        x: new Date(array[i].createdAt),
      });
    }
    return dps;
  };

  useEffect(() => {
    callDashboardApi();
  }, [payload]);

  useEffect(() => {
    setPayload({ ...payload, range: rangeValue, filterName });
  }, [rangeValue, filterName]);

  useEffect(() => {
    if (data && data.all_earnings) {
      const earnings = data.all_earnings;

      const options = {
        theme: "#4d1919", // "light1", "dark1", "dark2"
        animationEnabled: true,
        zoomEnabled: true,
        // title: {
        //   text: filterName,
        // },
        axisY: {
          gridThickness: 0,
          labelFormatter: function (e) {
            return "$" + CanvasJS.formatNumber(e.value, "#,#00");
          },
          stripLines: [
            {
              value: 0,
              showOnTop: true,
              color: "gray",
              thickness: 2,
            },
          ],
        },
        axisX: [
          {
            title: filterName + " to earnings",

            labelFormatter: function (e) {
              let format = "";
              switch (filterName) {
                case "DAY":
                  format = "DD MMM";
                  break;
                case "MONTH":
                  format = "MMM";
                  break;
                case "YEAR":
                  format = "YYYY";
                  break;
              }

              return CanvasJS.formatDate(e.value, format);
            },

            labelAngle: -20,
          },
        ],

        data: [
          {
            type: "area",
            color: "#b6d492",
            dataPoints: generateDataPoints(earnings),
          },
        ],
      };

      const setOptionEq = {
        theme: "light2",
        title: {
            text: "Equity"
        },
        data: [{
            type: "line",
            xValueFormatString: "MMM YYYY",
            yValueFormatString: "#,##0.00",
            dataPoints:  generateDataPointsEquity(earnings)
        }]
    }

      setoptions(options);
      setOptionEquity(setOptionEq);
    }
  }, [data]);

  const callDashboardApi = useCallback(() => {
    setIsLoading(true);
    callMemberApi(process.env.REACT_APP_BASEURL + "/dashboard", "POST", {
      type: "json",
      body: JSON.stringify(payload),
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          setData({ ...data.body });
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [payload]);

  useEffect(() => {
    callDashboardApi();
  }, []);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <Topsection
        custId={data?.profile_info?.member_code}
        title={`Welcome, ${data?.profile_info?.name || "Member"}`}
      ></Topsection>
      <div className="container container-table">
       
       <AccountSummary data={data}></AccountSummary>

        {/* Graph Container */}

        <Row className="mt-5">
          <Col></Col>
          <Col></Col>

          <Col>
            <DateRangePicker
              onChange={setValue}
              placeholder="Select date range"
              className="p-2"
              editable={false}
              format="MM/dd/yyyy hh:mm aa"
              showMeridian
              shouldDisableDate={afterToday()}
            />
          </Col>
          <Col className="text-right ">
            <div className="mx-2 ">
              <select
                onChange={(e) => setFilterName(e.target.value)}
                className="form-select mt-2 p-2"
                aria-label=".form-select-lg example"
              >
                <option value="">Select Filter</option>
                <option value="DAY">DAY</option>
                <option value="MONTH">MONTHLY</option>
                <option value="YEAR">YEARLY</option>
              </select>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CanvasJSChart
              options={options}
              /* onRef={ref => this.chart = ref} */
            />
            {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <CanvasJSChart
              options={optionsEquity}
              /* onRef={ref => this.chart = ref} */
            />
            {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
          </Col>
        </Row>

      </div>
    </>
  );
}
