import { Row, Col, Accordion, Container, Card } from "react-bootstrap";
import Topsection from "../../headers/Topsection";
import React, { useEffect, useState, useContext, useCallback } from "react";
import Pagination from "../Pagination";
import { callAdminApi, callMemberApi } from "../../utils/ApiInterface";
import { toast } from "react-toastify";
import LoadingSpinner from "../../utils/Spinner";
import Modal from "react-modal";
import { customStyles } from "../CustomStyle";
import Earningitem from "../admins/earnings/EarningItem";
import { useSelector } from "react-redux";
import AccountSummary from "./AccountSummary";
export default function Performance() {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [nPages, setNPages] = useState(1);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [years, setYears] = useState([]);
  const [moreYears, setMoreYears] = useState([]);
  const [rangeValue, setRangeValue] = useState([]);
  const profileInfo = useSelector((state) => state.memberloginInfo.data);
  const [payload, setPayload] = useState({
    search: "",
    page: 1,
    limit: recordsPerPage,
    equity_type: "",
    range: "",
  });

  useEffect(() => {
    var currentYear = new Date().getFullYear(),
      years = [];
    let startYear = currentYear - 7;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    years.reverse();
    if (years.length > 3) {
      let moreYears = years.splice(3, years.length);
      years = years.splice(0, 3);
      setMoreYears([...moreYears]);
    }
    setYears([...years]);
  }, []);
  const fetchData = (year, quarter) => {
    const startFullQuarter = new Date(year, quarter * 3, 1);
    const endFullQuarter = new Date(year, startFullQuarter.getMonth() + 3, 0);
    setRangeValue([startFullQuarter, endFullQuarter]);
    setModalOpen(true);
  };
  const setPayloadObject = () => {
    payload.selectAll = true;
    payload.member_id = profileInfo.member_id;
    payload.range = rangeValue;
    setPayload({ ...payload });
  };

  useEffect(() => {
    fetchApi();
  }, [payload]);

  useEffect(() => {
    setPayloadObject();
  }, [currentPage, rangeValue]);

  useEffect(() => {
    callDashboardApi();
  }, []);

  const callDashboardApi = useCallback(() => {
    setIsLoading(true);
    callMemberApi(process.env.REACT_APP_BASEURL + "/dashboard", "POST", {
      type: "json",
      body: JSON.stringify(payload),
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          setData({ ...data.body });
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [payload]);

  const fetchApi = () => {
    setIsLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/earnings/all",
      "POST",
      {
        type: "json",
        body: JSON.stringify(payload),
        auth: true,
      }
    )
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          const resp = data.body;

          const items = [...resp.rows];

          const totalH = items.reduce(
            (acc, item) =>
              item.status === 1 ? acc + Number(item.amount) : acc + 0,
            0
          );

          setContacts([...resp.rows]);
          const numberPages = Math.ceil(resp.count / recordsPerPage);
          setNPages(numberPages);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <Topsection
        custId={profileInfo?.member_code}
        title={`Historical Performance`}
      ></Topsection>{" "}
      <div className="container container-table bg-white">
        <Row className="mt-3 p-4 text-start">
          <Col className="mx-5" md="4">
          <AccountSummary alignment="col" data={data}></AccountSummary>
          </Col>
          <Col className="mx-5" md="6">
            <Row className="mt-1 text-start">
              <span className="bg-light-blue p-2 fs-4">
                ABN Account Performance tracking
              </span>
            </Row>
            <Row className="mt-1 text-start">
              <span className="bg-light-blue p-2 fs-4">
                Quaterly Statements
              </span>
            </Row>{" "}
            {years.map((item) => (
              <Row className="mt-3">
                <Col className="p-2 fs-3 text-secondary">{item}</Col>
                <Col
                  onClick={() => fetchData(item, 1)}
                  className="bg-grey btn fw-bold text-primary p-3 mx-1 text-bold"
                >
                  1st Quarter
                </Col>
                <Col
                  onClick={() => fetchData(item, 2)}
                  className="bg-grey btn  text-primary p-3 mx-1 fw-bold"
                >
                  2nd Quarter
                </Col>
                <Col
                  onClick={() => fetchData(item, 3)}
                  className="bg-grey btn text-primary p-3 mx-1 fw-bold"
                >
                  3rd Quarter
                </Col>
                <Col
                  onClick={() => fetchData(item, 4)}
                  className="bg-grey btn text-primary p-3 mx-1 fw-bold"
                >
                  4th Quarter
                </Col>
              </Row>
            ))}
            <Row className="mt-2">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="text-primary">
                    More Years <i className="mx-2 fa fa-plus-circle"></i>
                  </Accordion.Header>
                  <Accordion.Body>
                    {moreYears.map((mItem) => (
                      <Row className="mt-1">
                        <Col className="p-2 fs-3 text-secondary">{mItem}</Col>
                        <Col
                          onClick={() => fetchData(mItem, 1)}
                          className="btn bg-grey fw-bold text-primary p-3 mx-1 text-bold"
                        >
                          1st Quarter
                        </Col>
                        <Col
                          onClick={() => fetchData(mItem, 2)}
                          className="btn bg-grey text-primary p-3 mx-1 fw-bold"
                        >
                          2nd Quarter
                        </Col>
                        <Col
                          onClick={() => fetchData(mItem, 3)}
                          className="btn bg-grey text-primary p-3 mx-1 fw-bold"
                        >
                          3rd Quarter
                        </Col>
                        <Col
                          onClick={() => fetchData(mItem, 4)}
                          className="btn bg-grey text-primary p-3 mx-1 fw-bold"
                        >
                          4th Quarter
                        </Col>
                      </Row>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>
          </Col>
        </Row>
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Add Member"
        >
          <button onClick={(e) => setModalOpen(false)}>Close</button>

          <Earningitem contacts={contacts} action="viewonly"></Earningitem>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></Pagination>
        </Modal>{" "}
      </div>
    </>
  );
}
