import { Row, Col, CardGroup, Container, Card } from "react-bootstrap";

export default function AccountSummary({ data, alignment = "row" ,viewPersonal=true }) {
  return (
    <>
      <Row
        style={{ display: alignment === "row" ? "" : "none" }}
        className="mt-3 fs-5"
      >
        <CardGroup>
          <Card style={{display: !viewPersonal?'none':''}}>
            <Card.Body>
              <Card.Title className="text-success">
                <b>Personal Account Summary</b>
              </Card.Title>
              <Card.Text>
                <Container className="mt-4">
                  <Row>
                    <Col>Total Assets</Col>
                    <Col>${data.member_total || 0}</Col>
                  </Row>
                  <Row>
                    <Col>Relative equity in %</Col>
                    <Col>{data.member_relative_equity} %</Col>
                  </Row>
                </Container>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className="text-success">
                <b>Group Account Summary</b>
              </Card.Title>
              <Card.Text>
                <Container className="mt-4">
                  <Row>
                    <Col>Total Assets</Col>
                    <Col>${data.totalAssets || 0}</Col>
                  </Row>

                  <Row>
                    <Col>Top Performing Account</Col>
                    <Col>
                      ${data.top_performing_account?.totalAmount || 0} (
                      {data.top_performing_account?.relative_equity?.toFixed(
                        1
                      ) || 0}
                      %)
                    </Col>
                  </Row>
                  <Row>
                    <Col>Least Performing Account</Col>
                    <Col>
                      ${data.least_performing_account?.totalAmount || 0} (
                      {data.least_performing_account?.relative_equity?.toFixed(
                        1
                      ) || 0}
                      %)
                    </Col>
                  </Row>
                </Container>
              </Card.Text>
            </Card.Body>
            {/* <Card.Footer>
                <small className="text-muted">Last updated 3 mins ago</small>
              </Card.Footer> */}
          </Card>
        </CardGroup>
      </Row>

      <div style={{ display: alignment === "row" ? "none" : "" }}>
        <Row className="mt-3 fs-5">
          <Card>
            <Card.Body>
              <Card.Title className="text-success">
                <b>Personal Account Summary</b>
              </Card.Title>
              <Card.Text>
                <Container className="mt-4">
                  <Row>
                    <Col>Total Assets</Col>
                    <Col>${data.member_total || 0}</Col>
                  </Row>
                  <Row>
                    <Col>Relative equity in %</Col>
                    <Col>{data.member_relative_equity} %</Col>
                  </Row>
                </Container>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
        <Row className="mt-3">
          <Card>
            <Card.Body>
              <Card.Title className="text-success">
                <b>Group Account Summary</b>
              </Card.Title>
              <Card.Text>
                <Container className="mt-4">
                  <Row>
                    <Col>Total Assets</Col>
                    <Col>${data.totalAssets || 0}</Col>
                  </Row>

                  <Row>
                    <Col>Top Performing Account</Col>
                    <Col>
                      ${data.top_performing_account?.totalAmount || 0} (
                      {data.top_performing_account?.relative_equity?.toFixed(
                        1
                      ) || 0}
                      %)
                    </Col>
                  </Row>
                  <Row>
                    <Col>Least Performing Account</Col>
                    <Col>
                      ${data.least_performing_account?.totalAmount || 0} (
                      {data.least_performing_account?.relative_equity?.toFixed(
                        1
                      ) || 0}
                      %)
                    </Col>
                  </Row>
                </Container>
              </Card.Text>
            </Card.Body>
            {/* <Card.Footer>
                <small className="text-muted">Last updated 3 mins ago</small>
              </Card.Footer> */}
          </Card>
        </Row>
      </div>
    </>
  );
}
