import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";
import { callAdminApi } from "../../../utils/ApiInterface";

import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";

export default function AddEditEarning({ allChannels, allMembers }) {
  const initialState = {
    amount: "",
    channel_id: "",
    member_id: "",
  };

  const { setDataUpdated } = useContext(AppContext);
  const [inputData, setInputData] = useState(initialState);
  const [memberId, setMemberId] = useState("");
  const [channelId, setChannelId] = useState("");

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();

    callAdminApi(
      process.env.REACT_APP_BASEURL + `/admin/earnings/create`,
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({
          ...inputData,
          channel_id: channelId,
          member_id: memberId,
        }),
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDataUpdated(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <div id="customers" className="infowindow">
      <form
        className="needs-validation"
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <MDBCardBody className="text-black d-flex flex-column justify-content-center">
          <h3 className="mb-5  mt-3 text-uppercase fw-bold">
            Enter Details to Add Earning
          </h3>

          <MDBRow>
            <MDBCol md="11">
              <MDBInput
                wrapperClass="mb-4"
                label="Amount ($)"
                size="lg"
                name="amount"
                required={true}
                value={inputData.name}
                onChange={onChange}
                type="amount"
                min={0}
                max={100000}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="11">
              <label className="my-2">Select Member Name</label>
              <select
                onChange={(e) => setMemberId(e.target.value)}
                className="form-select mt-1 p-2"
                aria-label=".form-select-lg example"
              >
                <option value="">Select Member</option>
                {allMembers.map((item) => (
                  <option key={item.id} value={item.member_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="11">
              <label className="mt-4">Select Channel Name</label>
              <select
                onChange={(e) => setChannelId(e.target.value)}
                className="form-select mt-1 p-2"
                aria-label=".form-select-lg example"
              >
                <option value="">Select Channel</option>
                {allChannels.map((item) => (
                  <option key={item.id} value={item.channel_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </MDBCol>
          </MDBRow>
          <div className="text-center pt-3">
            <MDBBtn className="ms-1 mt-2 mb-2 row" color="warning" size="m">
              Submit
            </MDBBtn>
          </div>
        </MDBCardBody>
      </form>
    </div>
  );
}
