import { useState, useEffect, useCallback } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { Row, Col, Container } from "react-bootstrap";
import Topsection from "../../headers/Topsection";
import { callMemberApi } from "../../utils/ApiInterface";
import { toast } from "react-toastify";
import LoadingSpinner from "../../utils/Spinner";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Portfolio() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [options, setoptions] = useState({});

  useEffect(() => {
    callPortfolioApi();
  }, []);

  const callPortfolioApi = useCallback(() => {
    setIsLoading(true);
    callMemberApi(process.env.REACT_APP_BASEURL + "/portfolios", "GET", {
      type: "json",
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          setData({ ...data.body });
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  useEffect(() => {
    if (data && data.portfolio) {
      const array = data.portfolio;

      var dps = [];
      for (var i = 0; i < array.length; i++) {
        dps.push({
          name: array[i].channelInfo?.name,
          y: array[i].percentage,
          price: array[i].amount,
          exploded: i == 0 ? true : false,
        });
      }

      const options = {
        subtitles: [
          {
            dockInsidePlotArea: false,
          },
        ],
        legend: {
          verticalAlign: "center",
          horizontalAlign: "right",
          fontSize: 22,
          itemTextFormatter: function (e) {
            return `${e.dataPoint.name}               $${e.dataPoint.price} (${e.dataPoint.y}%)`;
          },

          fontWeight: "light",
        },

        data: [
          {
            type: "doughnut",
            showInLegend: true,
            showLabel: false,
            innerRadius: "55%",
            // indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            dataPoints: dps,
          },
        ],
      };

      setoptions(options);
    }
  }, [data]);

  return (
    <>
      {" "}
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <Topsection
        custId={data?.profile_info?.member_code}
        title={`Welcome, ${data?.profile_info?.name || "Member"}`}
      ></Topsection>{" "}
      <Container className="mt-3 text-start">
        <Row>
          <Col md="8" className="fs-5">
            <CanvasJSChart options={options} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
