import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function TradingItem({ items }) {
  const profileInfo = useSelector((state) => state.memberloginInfo.data);

  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
      style={{ height: "500px" }}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Description</th>
            <th>Categories</th>
          </tr>
        </thead>

        <tbody>
          <tr className="fs-5" style={{ display: items.length > 0 ? "none" : "" }}>
            <td></td>
            <td></td>
            <td>
              <p className="col-md-12">No data available</p>
            </td>
          </tr>
          {items.map((vData, index) => (
            <tr key={"_" + index + "_"}>
              <td>{new Date(vData.createdAt).toLocaleDateString()}</td>
              <td>
                {vData.from_member_id === profileInfo.member_id
                  ? "SENT"
                  : "RECEIVED"}
              </td>
              <td>{`${vData?.senderInfo?.name}(${vData?.senderInfo?.email}) has sent ${vData.equity}% to ${vData?.recieverInfo?.name} (${vData?.recieverInfo?.email})`}</td>
              <td>{vData?.channelTInfo?.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
