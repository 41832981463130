import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="bodyNav spinner-container">
      <div className="loading">
      </div>
    </div>
  );
}