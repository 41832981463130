import ContactItem from "./ContactItem";
import React, { useEffect, useState, useContext } from "react";
import Pagination from "../../Pagination";
import { callAdminApi, callMemberApi } from "../../../utils/ApiInterface";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../utils/Spinner";
export default function ConatctUs() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [nPages, setNPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);

  const [payload, setPayload] = useState({
    search: "",
    page: 1,
    limit: recordsPerPage,
  });

  const fetchApi = () => {
    setIsLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/contactus/list",
      "POST",
      {
        type: "json",
        body: JSON.stringify(payload),
        auth: true,
      }
    )
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          const resp = data.body;
          setContacts([...resp.rows]);
          const numberPages = Math.ceil(resp.count / recordsPerPage);
          setNPages(numberPages);
          setTotalRecords(resp.count);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setPayloadObject = () => {
    payload.search = search;
    payload.page = currentPage;
    payload.limit = recordsPerPage;
    setPayload({ ...payload });
  };
  useEffect(() => {
    setPayloadObject();
    setCurrentPage(1);
    fetchApi();
  }, [currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPayloadObject();
      setCurrentPage(1);
      fetchApi();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="col-md-11 border row mx-2  my-1">
        <b className="fs-3 m-2 text-start">Contact us</b>

        <div className="col-md-5">
          <input
            type="text"
            placeholder="Search by (name,email,subject,message)"
            value={search}
            className="form-control mt-2 p-2"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-md-12 m-3">
          <div>
            <p
              style={{ float: "left", fontSize: "20px" }}
              className="float-left"
            >
              <b>Total Count: {totalRecords}</b>
            </p>
          </div>

          <ContactItem contacts={contacts} />
        </div>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
}
