import { Row, Col, Container } from "react-bootstrap";
import { useState } from "react";

export default function Topsection({ title, custId }) {
  const [today, setDate] = useState(new Date());

  return (
    <Container className="container">
      <Row>
        <Col md="9" className="text-start">
          <h2>{title}</h2>
        </Col>
        <Col className="fs-5 mx-2">
          <Row>
            {today.toDateString() +
              " , " +
              today.toLocaleTimeString("en", {
                hour: "numeric",
                hour12: true,
                minute: "numeric",
              })}
          </Row>
          <Row>#{custId}</Row>
        </Col>
      </Row>
    </Container>
  );
}
