import { createSlice } from "@reduxjs/toolkit";

export const MemberLoginInfoSlice = createSlice({
  name: "memberloginInfo",
  initialState: {
    data: {},
  },
  reducers: {
    updateMemberLoginInfo: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateMemberLoginInfo } = MemberLoginInfoSlice.actions;

export default MemberLoginInfoSlice.reducer;
