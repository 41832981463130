import { useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch } from "react-redux";
import { callAdminApi } from "../utils/ApiInterface";
import { updateLoginInfo } from "../../store/slices/loginInfoSlice";

export default function NavbarAdmin() {
  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state.loginInfo.data);
  const shouldShowNavbar = () => {
    const { pathname } = location;
    return !pathname.includes("/login");
  };

  const handleLogout = () => {
    localStorage.setItem("admin-token", null);
    // setIsLoggedIn(false);
    setTimeout(function () {
      navigate("/admin/login");
    }, 700);
  };

  const fetchAdminInfo = () => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/fetchProfileInfo",
      "GET",
      {
        type: "json",
        auth: true,

        // body:JSON.stringify({leads:selctedIds})
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          const result = data.body;
          dispatch(updateLoginInfo(result));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkLogin = () => {
    const tokenData = localStorage.getItem("admin-token");

    if ((tokenData && tokenData === "null") || tokenData === "undefined") {
      return false;
    } else return true;
  };

  useEffect(() => {
    if (
      location.pathname.includes("admin") &&
      !location.pathname.includes("reset")
    ) {
      const tokenData = localStorage.getItem("admin-token");

      if (!(tokenData && tokenData !== "null" && tokenData !== "undefined")) {
        return navigate("/admin/login");
      } else {
        if (!(profileInfo && profileInfo.firstName)) {
          fetchAdminInfo();
        }
      }
    }
  }, []); //location
  return (
    shouldShowNavbar() && (
      <>
        <Navbar
          sticky="top"
          variant="light"
          collapseOnSelect
          expand="lg"
          style={{ backgroundColor: "#677b8f" }}
        >
          <Container>
            <img
              src="../logo_short.png"
              alt="African Bank Network Logo"
              width={50}
              className="logo"
            />

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  className={
                    location.pathname.includes("/admin/home")
                      ? "bg-svg-down active"
                      : "bg-svg-down"
                  }
                  as={Link}
                  to="/admin/home"
                >
                  Dashboard
                </Nav.Link>
                <Nav.Link
                  className={
                    location.pathname.includes("/admin/members") ? "active" : ""
                  }
                  as={Link}
                  to="/admin/members"
                >
                  Members
                </Nav.Link>

                <Nav.Link
                  className={
                    location.pathname.includes("/admin/channels")
                      ? "active"
                      : ""
                  }
                  as={Link}
                  to="/admin/channels"
                >
                  Income Channels
                </Nav.Link>

                <Nav.Link
                  className={
                    location.pathname.includes("/admin/earnings")
                      ? "active"
                      : ""
                  }
                  as={Link}
                  to="/admin/earnings"
                >
                  Earnings
                </Nav.Link>

                <Nav.Link
                  className={
                    location.pathname.includes("/admin/contactus")
                      ? "active"
                      : ""
                  }
                  as={Link}
                  to="/admin/contactus"
                >
                  Contact Us
                </Nav.Link>
              </Nav>

              <Nav
                className="mr-4"
                style={{
                  display:
                    location.pathname.includes("admin") &&
                    checkLogin() &&
                    profileInfo.email
                      ? "inline"
                      : "none",
                  marginLeft: "20%",
                }}
              >
                <NavDropdown
                  title={profileInfo.firstName + ` ` + profileInfo.lastName}
                  className="fs-5 d-flex text-white float-right"
                  id="basic-nav-dropdown"
                >
                  <Card                   style={{ width: "20rem" }}
  bg="light" text="dark" className="mb-2 ">
                    <Card.Header className="text-start">
                      <i className="fa fa-envelope fs-5"></i>
                      <span className="mx-2 fs-5">{profileInfo.email}</span>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <i className="fa fa-mobile fs-5"></i>{" "}
                        <span className="mx-1 fs-5">
                          +
                          {profileInfo.countryCode +
                            ` ` +
                            profileInfo.phoneNumber}
                        </span>{" "}
                      </Card.Text>
                      <button className="btn-get-danger" onClick={handleLogout}>
                        <span className="fs-5">Logout</span>
                      </button>
                    </Card.Body>
                  </Card>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <br />
      </>
    )
  );
}
