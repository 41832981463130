import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MDBCardBody, MDBRow } from "mdb-react-ui-kit";
import Modal from "react-modal";
import { AppContext } from "../../context/AppContext";
import { customStyles } from "../CustomStyle";
const AdminLogin = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [forgotP, setFCredentials] = useState({ femail: "" });
  const { setAdminIsLoggedIn } = useContext(AppContext);
  const [forgotModal, setForgotModal] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const tokenData = localStorage.getItem("admin-token");

    if (tokenData && tokenData !== "null" && tokenData !== "undefined") {
      setAdminIsLoggedIn(true);
      return navigate("/admin/home");
    }
  }, [navigate]);

 

  const requestPassword = async (e) => {
    const response = await fetch(
      process.env.REACT_APP_BASEURL + "/admin/forgotPassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: forgotP.femail,
        }),
      }
    );
    const data = await response.json();

    if (data.code === 200) {
      // Save the auth token and redirect
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setForgotModal(false);
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    const response = await fetch(
      process.env.REACT_APP_BASEURL + "/admin/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: credentials.email,
          password: credentials.password,
        }),
      }
    );
    const data = await response.json();

    if (data.code === 200) {
      // Save the auth token and redirect
      localStorage.setItem("admin-token", data.body?.authtoken);
      setAdminIsLoggedIn(true);
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      const path = data.body.redirectedPath
        ? data.body.redirectedPath
        : "/admin/home";

      setTimeout(function () {
        window.location.href = path;
      }, 600);
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onChangeForgot = (e) => {
    setFCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className="row  justify-content-center login-back">
      <div className="col-md-4 border bg-white h-200 text-center mt-2 row mx-5 my-3">
        <form
          className="needs-validation"
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
        >
          <MDBCardBody className="text-black d-flex flex-column justify-content-center">
            <div className="mt-3">
              <img
                src="../logo.png"
                alt="Logo"
                style={{ color: "#ff6219", width: "30%" }}
              />
            </div>
            <h3 className="mb-5 mt-5 text-uppercase fw-bold">Admin Login</h3>

            <MDBRow>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  required
                  type="email"
                  className="form-control"
                  value={credentials.email}
                  onChange={onChange}
                  id="email"
                  name="email"
                  maxLength={100}
                  autoComplete="off"
                  aria-describedby="emailHelp"
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-1">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  value={credentials.password}
                  onChange={onChange}
                  name="password"
                  id="password"
                  maxLength={25}
                  autoComplete="new-password"
                />
              </div>
            </MDBRow>
            <MDBRow>
              <p className="mt-2 text-end">
                {" "}
                <a href="#" onClick={() => setForgotModal(true)}>Forgot Password?</a>
              </p>

              <div className="mb-3 mt-3">
                <button
                  type="submit"
                  className="btn btn-xs w-50 justify-content-center mt-2 btn-success"
                >
                  Submit
                </button>
              </div>

              <Modal
                isOpen={forgotModal}
                // onAfterOpen={afterOpenModal}
                style={customStyles}
                appElement={document.getElementById("root")}
                contentLabel="Example Modal"
              >
                {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                <button onClick={() => setForgotModal(false)}>close</button>

                <div className="mb-3 mt-4 ">
                  <span className="fs-6">
                    Please enter email address below , We will send you an email
                    for further instructions.
                  </span>

                  <input
                    required
                    type="email"
                    placeholder="abc@example.com"
                    className="mt-2 form-control"
                    value={forgotP.femail}
                    onChange={onChangeForgot}
                    id="Femail"
                    name="femail"
                    aria-describedby="emailHelp"
                  />
                </div>

                <button onClick={requestPassword} className="btn btn-info">
                  Request for a Password
                </button>
              </Modal>
            </MDBRow>
          </MDBCardBody>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
