import React, { useState } from "react";
import { toast } from "react-toastify";
import { callMemberApi } from "../../../utils/ApiInterface";
import Footer from "./Footer";
import NavbarTop from "../../../headers/NavbarTop";
import LoadingSpinner from "../../../utils/Spinner";
import swal from "sweetalert";

export default function WebHome() {
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const [inputData, setInputData] = useState(initialState);

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setIsLoading(true);
    callMemberApi(
      process.env.REACT_APP_BASEURL + `/others/create-contactus`,
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify(inputData),
      }
    )
      .then(async (data) => {
        setIsLoading(false);

        if (data.code === 200) {
          swal(
            "Success!",
            "Thankyou for reaching out. We have recieved your message, Admin will contact you soon.",
            "success"
          );

          setInputData(initialState);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <NavbarTop></NavbarTop>

      <main className="main">
        <section id="hero" className="hero section dark-background">
          <img
            src={process.env.REACT_APP_FRONTEND_URL + "/assets/img/hero-bg.jpg"}
            alt=""
            data-aos="fade-in"
          />

          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h2>Welcome to Our B2B Network</h2>
                <p>
                  To create an ecosystem that supports, provide the tools and
                  empowers businesses in the network to expand, grow and
                  progress to levels of optimal success
                </p>
                <a href="#about" className="btn-get-started">
                  Learn More
                </a>

                <a href="/login" className="mx-2 btn-get-info">
                  Member Login
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="home1" className="about section">
          <div className="container">
            <div className="row gy-3">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <img
                  src={
                    process.env.REACT_APP_FRONTEND_URL +
                    "/logo-without-back.png"
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <div className="info-content ps-0 ps-lg-3">
                  <h3>Welcome to African Business 2 Business Network</h3>

                  <p>
                    The premier networking group of African owned Healthcare
                    businesses in USA. Our diverse membership represents many
                    professionals in the Pharmaceutical, Medical and Allied
                    Healthcare sectors who have joined forces to build a
                    formidable economic power and support each other through
                    referrals and networking. Join our organization and grow
                    your business!
                  </p>
                  <a className="btn-get-info text-white">Join us</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about section">
          <div className="container section-title" data-aos="fade-up">
            <h2 className="info-content"><b>What We Do</b></h2>
          </div>

          <div className="container">
            <div className="row gy-3">
              <div className="col-lg-6">
                <img
                  src={
                    process.env.REACT_APP_FRONTEND_URL + "/assets/img/about.jpg"
                  }
                  alt=""
                  className="img-fluid-80"
                />
              </div>

              <div className="col-lg-6  d-flex flex-column justify-content-center">
                <div className="about-content  mx-4  ps-0 ps-lg-3">
                  <h3 className="text-start">Advocacy</h3>

                  <ul className="text-start">
                    <li>
                      <p className="fs-6">
                      • Advocate for regulations that protects the interest of
                        African-American Pharmaceutical and Healthcare based
                        businesses
                      </p>
                    </li>
                    <li>
                      <p className="fs-6">
                      • Connect the network to and facilitate collaboration
                        with other like-minded organizations for synergy in
                        advocacy and campaigns for the betterment of the
                        professional affairs of the network members
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container my-2">
            <div className="row justify-content-between">
              <div className="col-lg-5  mx-5 d-flex flex-column justify-content-center">
                <div className="about-content">
                  <h3 className="my-2 text-start">Peer Mentorship</h3>

                  <ul className="text-start">
                    <li>
                      <p className="fs-6">
                        • Mentor new and less experienced business owners to
                        achieve business success
                      </p>
                    </li>
                    <li>
                      <p className="fs-6">
                        • Share and model best business practices to the network
                        community to ensure collective success
                      </p>
                    </li>

                    <li>
                      <p className="fs-6">
                        • Seminars to disseminate best business practices and
                        relevant new regulations to the network’s business
                        community
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="tabs-tab-1">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/peer.jpg"
                      }
                      alt=""
                      className="img-fluid-80"
                    />
                  </div>

                  <div className="tab-pane fade" id="tabs-tab-2">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/tabs-2.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="tab-pane fade" id="tabs-tab-3">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/tabs-3.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container  my-2">
            <div className="row gy-3">
              <div className="col-lg-6  my-5">
                <img
                  src={
                    process.env.REACT_APP_FRONTEND_URL +
                    "/assets/img/empowerment.jpg"
                  }
                  alt=""
                  className="img-fluid-80"
                />
              </div>

              <div className="col-lg-6  d-flex flex-column justify-content-center">
                <div className="about-content ps-0 ps-lg-3">
                  <h3 className="my-4 text-start">
                    Economic Empowerment of Members
                  </h3>

                  <ul className="text-start">
                    <li>
                      <p className="fs-6">
                        • Build equity and retirement funds through joint
                        venture with network partners
                      </p>
                    </li>
                    <li>
                      <p className="fs-6">
                        • Build individual wealth through collaborative business
                        ventures
                      </p>
                    </li>

                    <li>
                      <p className="fs-6">
                        • Retain wealth within the network community by taking
                        advantage of the synergy within the business ecosystem
                      </p>
                    </li>
                    <li>
                      <p className="fs-6">
                        • Enlarge the economic footprint of network members in
                        the field of professional endeavor
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container  my-2">
            <div className="row justify-content-between">
              <div className="col-lg-4 mx-5 d-flex flex-column justify-content-center">
                <div className="about-content">
                  <h3 className="text-start my-1">Community Building</h3>

                  <ul className="text-start">
                    <li>
                      <p className="fs-6">
                        • Collaborate with like minded organizations to support
                      </p>
                    </li>
                    <li>
                      <p className="fs-6">
                        • Provide academic grants and scholarships to deserving
                        indigent African-American students
                      </p>
                    </li>

                    <li>
                      <p className="fs-6">
                        • Connecting your business to the local community
                      </p>
                    </li>
                    <li>
                      <p className="fs-6">
                        • Facilitate other give-back programs targeted to
                        African-American nonprofits and institutions
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6  my-3">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="tabs-tab-1">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/community.jpg"
                      }
                      alt=""
                      className="img-fluid-80"
                    />
                  </div>

                  <div className="tab-pane fade" id="tabs-tab-2">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/tabs-2.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="tab-pane fade" id="tabs-tab-3">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/tabs-3.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="partners" className="portfolio section">
          <div className="container section-title" data-aos="fade-up">
            <h2><b>Our Ecosystem Partners</b></h2>
          </div>

          <div className="container">
            <div
              className="isotope-layout"
              data-default-filter="*"
              data-layout="masonry"
              data-sort="original-order"
            >
              <div className="row g-0 isotope-container">
                <div className="col-xl-4 col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/app-1.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/app-1.jpg"
                        }
                        data-gallery="portfolio-gallery-app"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/app-2.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/product-1.jpg"
                        }
                        data-gallery="portfolio-gallery-product"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/app-3.png"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/branding-2.jpg"
                        }
                        data-gallery="portfolio-gallery-branding"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 portfolio-item isotope-item filter-books">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/app-4.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/books-2.jpg"
                        }
                        data-gallery="portfolio-gallery-book"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/app-5.png"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/app-3.jpg"
                        }
                        data-gallery="portfolio-gallery-app"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/product-3.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/product-3.jpg"
                        }
                        data-gallery="portfolio-gallery-product"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/branding-3.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/branding-3.jpg"
                        }
                        data-gallery="portfolio-gallery-branding"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item isotope-item filter-books">
                  <div className="portfolio-content h-100">
                    <img
                      src={
                        process.env.REACT_APP_FRONTEND_URL +
                        "/assets/img/portfolio/books-3.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          "/assets/img/portfolio/books-3.jpg"
                        }
                        data-gallery="portfolio-gallery-book"
                        className="glightbox preview-link"
                      >
                        <i className="bi bi-zoom-in"></i>
                      </a>
                      <a
                        href="portfolio-details.html"
                        title="More Details"
                        className="details-link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="contact section">
          <div className="container section-title">
            <h2><b>Contact Us</b></h2>
            <p>Learn More about African B2B Network</p>
          </div>

          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5">
                <div className="info-wrap">
                  <div className="info-item d-flex">
                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                    <div>
                      <h3 className="text-start">Address</h3>
                      <p>201 Winston Lane West Chester PA 19382</p>
                    </div>
                  </div>

                  <div
                    className="info-item d-flex"
                   
                  >
                    <i className="bi bi-telephone flex-shrink-0"></i>
                    <div>
                      <h3 className="text-start">Call Us</h3>
                      <p>+1 (310) 462-9529</p>
                    </div>
                  </div>
                  <div
                    className="info-item d-flex"
                    
                  >
                    <i className="bi bi-envelope-arrow-up-fill flex-shrink-0"></i>
                    <div>
                      <h3 className="text-start">Email Us</h3>
                      <p>africanb2bnetwork@gmail.com</p>
                    </div>
                  </div>

                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.597459679752!2d-75.63668322419923!3d39.90564397152635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6f75a4308d117%3A0x4ebe9dc1b1632182!2s201%20Winston%20Ln%2C%20West%20Chester%2C%20PA%2019382!5e0!3m2!1sen!2sus!4v1726307787793!5m2!1sen!2sus"
                    style={{ border: 0, width: "100%", height: "270px" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>

              <div className="col-lg-7">
                <form
                  className="needs-validation"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  noValidate
                >
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <label htmlFor="name-field" className="pb-2">
                        Your Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name-field"
                        className="form-control"
                        maxLength={100}
                        required={true}
                        value={inputData.name}
                        onChange={onChange}
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="email-field" className="pb-2">
                        Your Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email-field"
                        maxLength={100}
                        required={true}
                        value={inputData.email}
                        onChange={onChange}
                      />
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="subject-field" className="pb-2">
                        Subject
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject-field"
                        maxLength={300}
                        required={true}
                        value={inputData.subject}
                        onChange={onChange}
                      />
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="message-field" className="pb-2">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        name="message"
                        rows="10"
                        id="message-field"
                        maxLength={500}
                        required={true}
                        value={inputData.message}
                        onChange={onChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="text-center  pt-3">
                    <button
                      className="ms-1 mb-2 text-white btn-get-info"
                      color="warning"
                      size="m"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </>
  );
}
