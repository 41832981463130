import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import Modal from "react-modal";
import AddEditEarning from "./AddEditEarning";
import { callAdminApi } from "../../../utils/ApiInterface";
import LoadingSpinner from "../../../utils/Spinner";
import { customStyles } from "../../CustomStyle";
export default function Earningitem({ contacts, action }) {
  const { setDataDeleted, isDataUpdated } = useContext(AppContext);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [userDetail, setuserDetail] = useState({});
  const [isLoading, setLoading] = useState(false);

  const editDetails = (id) => {
    const findItem = contacts.find((item) => item.earning_id === id);
    if (findItem) {
      setuserDetail(findItem);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    setModalOpen(false);
  }, [isDataUpdated]);

  const deleteuser = (event, id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptFunc(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

    const acceptFunc = (id) => {
      setLoading(true);
      callAdminApi(
        process.env.REACT_APP_BASEURL + "/admin/earnings/delete",
        "DELETE",
        {
          type: "json",
          auth: true,
          body: JSON.stringify({
            id: id,
          }),
          // body:JSON.stringify({leads:selctedIds})
        }
      )
        .then(async (data) => {
          setLoading(false);
          if (data.code === 200) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setDataDeleted(true);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
          toast.success(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <div
        id="customers"
        className="infowindow overflow-auto"
        style={{ height: "500px" }}
      >
        <div
          className="text-center  m-5  row"
          style={{ display: contacts.length > 0 ? "none" : "" }}
        >
          <h3 className="col-md-12">No data available for this selected range</h3>
        </div>
        <table style={{ display: contacts.length > 0 ? "" : "none" }}>
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Member Name</th>
              <th>Pharmacy Name</th>
              <th>Founder Equity</th>
              <th>Channel Name</th>
              <th>Earning($)</th>
              <th>Non Founder Credit($)</th>
              <th>Founder Credit($)</th>
              <th>Total Earnings($)</th>
              <th>Created Date</th>
              <th>Status($)</th>
              <th style={{ display: action == "viewonly" ? "none" : "" }}>
                Action
              </th>
            </tr>
          </thead>
          <tr></tr>
          <tbody>
            {contacts.map((user, index) => (
              <tr key={"_earning" + index + "_" + user.earning_id}>
                <td>{index + 1}</td>
                <td>{user.memberInfo?.name}</td>
                <td>{user.memberInfo?.pharmacy_name}</td>
                <td>{user.memberInfo?.founder_equity} %</td>
                <td>{user.channelInfo?.name}</td>
                <td>{user.amount}</td>
                <td>{user.non_founder_credit}</td>
                <td>{user.founder_credit}</td>
                <td>{user.total_earning}</td>

                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                <td>
                  <label
                    className={
                      user.status === 1
                        ? "badge badge-success"
                        : "badge badge-danger "
                    }
                  >
                    {user.status === 1 ? "Current" : "Past/Closed"}
                  </label>
                </td>

                <td style={{ display: action == "viewonly" ? "none" : "" }}>
                  <button
                    onClick={(e) => deleteuser(e, user.earning_id)}
                    className="btn btn-xs btn-danger"
                  >
                    Delete
                  </button>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Example Modal"
        >
          <button onClick={(e) => setModalOpen(false)}>Close</button>

          <AddEditEarning
            action="update"
            userDetail={userDetail}
          ></AddEditEarning>
        </Modal>
      </div>
    </>
  );
}
